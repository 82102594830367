import * as React from "react";
import Table from "@cloudscape-design/components/table";
import Box from "@cloudscape-design/components/box";
import Button from "@cloudscape-design/components/button";
import TextFilter from "@cloudscape-design/components/text-filter";
import Header from "@cloudscape-design/components/header";
import Pagination from "@cloudscape-design/components/pagination";
import CollectionPreferences from "@cloudscape-design/components/collection-preferences";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Link from "@cloudscape-design/components/link";
import Cards from "@cloudscape-design/components/cards";
import SideNavigation from "@cloudscape-design/components/side-navigation";
import Badge from "@cloudscape-design/components/badge";
import TopNavigation from "@cloudscape-design/components/top-navigation";
import AppLayout from "@cloudscape-design/components/app-layout";
import Flashbar from "@cloudscape-design/components/flashbar";
import './active-jobs.css'

import { Link as HyperLink, useNavigate } from "react-router-dom";

import { useCollection } from '@cloudscape-design/collection-hooks';

// import jobsCollection from './active-jobs.json';
import Container from "@cloudscape-design/components/container";
import ProgressBar from "@cloudscape-design/components/progress-bar";
import ColumnLayout from "@cloudscape-design/components/column-layout";
import StatusIndicator from "@cloudscape-design/components/status-indicator";

export default () => {
  const [activeHref, setActiveHref] = React.useState(
    ""
  );
  
  const [
    selectedItems,
    setSelectedItems 
  ] = React.useState([{ name: "", id: 0, language: "", stage: "", stageDescription: "", progress: 0,  }]);
  
  const [
    currentPageIndex,
    setCurrentPageIndex
  ] = React.useState(1);

  const [jobsCol, setJobsCol] = React.useState([{ name: "", id: 0, language: "", stage: "", stageDescription: "", progress: 0,  }]);
  
  function getJobsFromDb() {
    let auth_key = "" 
      if (localStorage.getItem('auth-key') !== null) {
        auth_key = JSON.parse(JSON.stringify(localStorage.getItem('auth-key')))
      }
      console.log(`##${auth_key}##`);
      return fetch('https://tyxvvj2zej.execute-api.us-west-2.amazonaws.com/Prod/jobs?state=InProcess', {
        method: 'GET', 
        headers: new Headers ({
          'Authorization': auth_key
        })
      })
      .then(res => res.json())
  }

  React.useEffect(() => {
    setSelectedItems([]); // clear selected items.
    
    // let localActiveJobs = localStorage.getItem('activeJobs');
    // let localActiveJobsObj = [];

    // if (localActiveJobs) {
    //   localActiveJobsObj = JSON.parse(localActiveJobs);
    // }

    // if (localActiveJobsObj.length > 0) {
    //   setJobsCol(localActiveJobsObj);
    // }
    // else {
    getJobsFromDb().then(
      (result) => {
        console.log(result);
        setJobsCol(result);
        //localStorage.setItem('jobList', JSON.stringify(result));
      },
      (error) => {
        // handle errors.
        localStorage.removeItem('auth-key');
        // window.location.href = '/';
        console.log(error);
      }
    )
    // }
  }, []);

  const EmptyState = () => {
    return (
      <Container>
        <Box textAlign="center" color="inherit">
          <b>No Jobs</b>
          <Box
            padding={{ bottom: "s" }}
            variant="p"
            color="inherit"
          >
            No jobs to display.
          </Box>
          <HyperLink to="/new-job">Create Jobs</HyperLink>
        </Box>
      </Container>
    )
  }

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    jobsCol,
    {
      filtering: {
        empty: (
          <EmptyState></EmptyState>
        ),
        noMatch: (
          <EmptyState />
        ),
      },
      pagination: { pageSize: 6 },
      sorting: {},
      selection: {},
    }
  );

  const SideNav = () => {
    return (

      <SideNavigation
        activeHref={activeHref}
        header={{ href: "#/", text: "Job Actions" }}
        onFollow={event => {
          if (!event.detail.external) {
            event.preventDefault();
            //alert(event.detail.href);
            setActiveHref(event.detail.href);
            window.location.href = event.detail.href;
          }
        }}
        items={[
          { type: "link", text: "Create New Job", href: "/new-job" },
          { type: "link", text: "Active Media Jobs", href: "/active-jobs" },
          { type: "link", text: "Media Job Archive", href: "/archived-jobs" },
          { type: "divider" },
          {
            type: "link",
            text: "Notifications",
            href: "/notifications",
            info: <Badge color="red">23</Badge>
          },
          // {
          //   type: "link",
          //   text: "Documentation",
          //   href: "https://example.com",
          //   external: true
          // }
        ]}
      />
      
    )
  }

  const createJob = () => {
    // const navigate = useNavigate();
    // navigate("/new-job", {replace: true});
    window.location.href = '/new-job';
  }

  const viewArchivedJobs = () => {
    // const navigate = useNavigate();
    // navigate("/new-job", {replace: true});
    window.location.href = '/archived-jobs';
  }

  return (
    
    <SpaceBetween size="m">
      
        <Header
          counter="(3)"
          actions={
            <SpaceBetween direction="horizontal" size="s">
              <Button onClick={viewArchivedJobs}>View Archived Jobs</Button>
              <Button onClick={createJob} variant="primary">
                Create New Job
              </Button>
            </SpaceBetween>
          }
          info={<Link variant="info">Info</Link>}
        >
          Active Media Jobs
        </Header>
        
        {/* <Flashbar items={items} /> */}
        <Cards
          onSelectionChange={({ detail }) =>
            setSelectedItems(detail.selectedItems)
          }
          selectedItems={selectedItems}
          ariaLabels={{
            itemSelectionLabel: (e, t) => `select ${t.name}`,
            selectionGroupLabel: "Item selection"
          }}
          cardDefinition={{
            header: e => <div><a href={"/active-jobs/" + e.id}>{e.name}</a></div>,
            sections: [
              {
                id: "description",
                // header: "Job Progress",
                content: e=><ColumnLayout columns={2} variant="text-grid">
                  <div>
                    <div>
                      <h4 className="job-sub-header">Job Step:</h4>
                      {e.stage}
                    </div>
                    <div>
                      <h4 className="job-sub-header">Job Status:</h4>
                      <StatusIndicator type="in-progress">
                        In Process
                      </StatusIndicator>
                    </div>
                  </div>
                <SpaceBetween size="l">
                  <ProgressBar
                    value={e.progress}
                    variant="key-value"
                    additionalInfo={`Currently on ${e.stageDescription} stage.`}
                    // description="Progress bar description"
                    label="Job Progress"
                  />
                    {/* <Link
                      href="/"
                      target="_blank"
                      rel="noopener noreferrer"
                      external={true}
                    >
                      Value with external link
                    </Link> */}
                  
                </SpaceBetween>
              </ColumnLayout>
              }
            ]
          }}
          cardsPerRow={[
            { cards: 2 },
            { minWidth: 500, cards: 2 }
          ]}
          items={items}
          loadingText="Loading resources"
          // selectionType="single"
          trackBy="name"
          visibleSections={["description", "stage"]}
          empty={
            <Box textAlign="center" color="inherit">
              <b>No resources</b>
              <Box
                padding={{ bottom: "s" }}
                variant="p"
                color="inherit"
              >
                No resources to display.
              </Box>
              <Button>Create resource</Button>
            </Box>
          }
          filter={
            <TextFilter
              {...filterProps}
              // countText={getMatchesCountText(filteredItemsCount)}
              filteringAriaLabel="Filter instances"
            />
          }
          header={
            <Header
              counter={
                selectedItems.length
                  ? "(" + selectedItems.length + "/10)"
                  : "(10)"
              }
            >
              Search Active Jobs
            </Header>
          }
          pagination={<Pagination {...paginationProps} />}
          preferences={
            <CollectionPreferences
              title="Preferences"
              confirmLabel="Confirm"
              cancelLabel="Cancel"
              preferences={{
                pageSize: 6,
                visibleContent: [
                  "description",
                  "type",
                  "size"
                ]
              }}
              pageSizePreference={{
                title: "Select page size",
                options: [
                  { value: 6, label: "6 resources" },
                  { value: 12, label: "12 resources" }
                ]
              }}
              visibleContentPreference={{
                title: "Select visible content",
                options: [
                  {
                    label: "Main distribution properties",
                    options: [
                      {
                        id: "description",
                        label: "Description"
                      },
                      { id: "type", label: "Type" },
                      { id: "size", label: "Size" }
                    ]
                  }
                ]
              }}
            />
          }
        />
    </SpaceBetween>
  );
}

function getMatchesCountText(filteredItemsCount: number | undefined): string | undefined {
  return `Found ${filteredItemsCount} Items...`;
}
