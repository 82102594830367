import { Button, Container, FormField, Header, Input, Link, Select, SpaceBetween } from "@cloudscape-design/components"
import React from "react";

export default () => {

  const [availableTitles, setAvailableTitles] = React.useState([{ label: "None Found. Please upload video to S3.", value: "" }]);

  function getTitlesFromS3() {
    let auth_key = "" 
      if (localStorage.getItem('auth-key') !== null) {
        auth_key = JSON.parse(JSON.stringify(localStorage.getItem('auth-key')))
      }
      console.log(`##${auth_key}##`);
      return fetch('https://tyxvvj2zej.execute-api.us-west-2.amazonaws.com/Prod/titles', {
        method: 'GET', 
        headers: new Headers ({
          'Authorization': auth_key
        })
      })
      .then(res => {
        console.log(res.status);
        return res.json();
      })
  }

  React.useEffect(() => {
    let localTitleList = localStorage.getItem('titleList');
    let localTitleListObj = [];

    if (localTitleList) {
      localTitleListObj = JSON.parse(localTitleList);
    }

    if (localTitleList) {
      setAvailableTitles(localTitleListObj);
    }
    else {
      getTitlesFromS3().then(
        (result) => {
          console.log(result);
          setAvailableTitles(result);
          localStorage.setItem('titleList', JSON.stringify(result));
        },
        (error) => {
          // handle errors.
          console.log(error);
          localStorage.removeItem('auth-key');
        }
      )
    }
  }, []);

  const [jobName, setJobName] = React.useState("");
  const [
      selectedTitle,
      setSelectedTitle
  ] = React.useState({ label: "Please Select One", value: "0" });

  const [
    selectedSourceLanguage,
    setSelectedSourceLanguage
  ] = React.useState({ label: "Please Select One", value: "0" });
  
  const [
    selectedDestinationLanguage,
    setSelectedDestinationLanguage
  ] = React.useState({ label: "Please Select One", value: "0" });

  const createJob = () => {
    // const navigate = useNavigate();
    // navigate("/new-job", {replace: true});
    const payload = {
      "titleS3Path": selectedTitle.value,
      "jobName": jobName,
      "sourceLanguageValue": selectedSourceLanguage.value, 
      "destinationLanguageValue": selectedDestinationLanguage.value,
      "sourceLanguageLabel": selectedSourceLanguage.label,
      "destinationLanguageLabel": selectedDestinationLanguage.label,
    }

    fetch('https://tyxvvj2zej.execute-api.us-west-2.amazonaws.com/Prod/jobs', {
      method: 'POST', 
      cache: 'no-cache',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('auth-key')}`
      },
      body: JSON.stringify(payload)
    })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
          window.location.href = '/active-jobs';
        },
        (error) => {
          // handle errors.
          console.log(error);
          localStorage.removeItem('auth-key');
        }
      )
    // window.location.href = '/new-job';
  }

  const viewArctiveJobs = () => {
    // const navigate = useNavigate();
    // navigate("/new-job", {replace: true});
    window.location.href = '/active-jobs';
  }

  const refreshTitlesFromS3 = () => {
    getTitlesFromS3().then(
      (result) => {
        console.log(result);
        setAvailableTitles(result);
        localStorage.setItem('titleList', JSON.stringify(result));
      },
      (error) => {
        // handle errors.
        console.log(error.status);
        console.log(JSON.stringify(error));
      }
    )
  }

  return (
    <SpaceBetween size="m">
      <Header
        actions={
          <SpaceBetween direction="horizontal" size="s">
            <Button onClick={viewArctiveJobs}>View Active Jobs</Button>
            <Button onClick={createJob} variant="primary">
              Create Job
            </Button>
          </SpaceBetween>
        }
        info={<Link variant="info">Info</Link>}
      >
        Create New Media Job
      </Header>
      <Container>
          <SpaceBetween size="m">
          <FormField
              description="Please enter a brief description of the Job."
              label="Job Name"
              >
              <Input
                  value={jobName}
                  onChange={event =>
                    setJobName(event.detail.value)
                  }
                  placeholder="Job description."
              />
          </FormField>
          {/* <FormField
              description="Please choose a title (video) that was previously uploaded to S3."
              label="Title to Process"
              secondaryControl={<Button iconName="refresh" />}
              >
              <Select
                      options={[
                          { label: "sg-00dcd368", value: "1" },
                          { label: "sg-02dcd36a", value: "2" },
                          { label: "sg-04dcd36c", value: "3" },
                          { label: "sg-05fa4668", value: "4" },
                          { label: "sg-064a9062", value: "5" }
                      ]} />
          </FormField> */}
          <FormField
              description="Please choose a title (video) that was previously uploaded to S3."
              label="Title to Process"
              secondaryControl={<Button onClick={refreshTitlesFromS3} iconName="refresh" />}
              >
              <Select
                      selectedOption={selectedTitle}
                      onChange={({ detail }) =>
                      setSelectedTitle(JSON.parse(JSON.stringify(detail.selectedOption)))
                      }
                      options={availableTitles}
                      selectedAriaLabel="Selected"
              />
          </FormField>
          <FormField
              description="What Language is spoken in this Title?"
              label="Language to Translate From">
              <Select
                      selectedOption={selectedSourceLanguage}
                      onChange={({ detail }) =>
                      setSelectedSourceLanguage(JSON.parse(JSON.stringify(detail.selectedOption)))
                      }
                      options={[
                          { label: "Afrikaans", value: "af" },
                          { label: "Albanian", value: "sq" },
                          { label: "Amharic", value: "am" },
                          { label: "Arabic", value: "ar" },
                          { label: "Armenian", value: "hy" },
                          { label: "Azerbaijani", value: "az" },
                          { label: "Bengali", value: "bn" },
                          { label: "Bosnian", value: "bs" },
                          { label: "Bulgarian", value: "bg" },
                          { label: "Catalan", value: "ca" },
                          { label: "Chinese (Simplified)", value: "zh" },
                          { label: "Chinese (Traditional)", value: "zh-TW" },
                          { label: "Croatian", value: "hr" },
                          { label: "Czech", value: "cs" },
                          { label: "Danish", value: "da" },
                          { label: "Dari", value: "fa-AF" },
                          { label: "Dutch", value: "nl" },
                          { label: "English", value: "en" },
                          { label: "Estonian", value: "et" },
                          { label: "Farsi (Persian)", value: "fa" },
                          { label: "Filipino, Tagalog", value: "tl" },
                          { label: "Finnish", value: "fi" },
                          { label: "French", value: "fr" },
                          { label: "French (Canada)", value: "fr-CA" },
                          { label: "Georgian", value: "ka" },
                          { label: "German", value: "de" },
                          { label: "Greek", value: "el" },
                          { label: "Gujarati", value: "gu" },
                          { label: "Haitian Creole", value: "ht" },
                          { label: "Hausa", value: "ha" },
                          { label: "Hebrew", value: "he" },
                          { label: "Hindi", value: "hi" },
                          { label: "Hungarian", value: "hu" },
                          { label: "Icelandic", value: "is" },
                          { label: "Indonesian", value: "id" },
                          { label: "Irish", value: "ga" },
                          { label: "Italian", value: "it" },
                          { label: "Japanese", value: "ja" },
                          { label: "Kannada", value: "kn" },
                          { label: "Kazakh", value: "kk" },
                          { label: "Korean", value: "ko" },
                          { label: "Latvian", value: "lv" },
                          { label: "Lithuanian", value: "lt" },
                          { label: "Macedonian", value: "mk" },
                          { label: "Malay", value: "ms" },
                          { label: "Malayalam", value: "ml" },
                          { label: "Maltese", value: "mt" },
                          { label: "Marathi", value: "mr" },
                          { label: "Mongolian", value: "mn" },
                          { label: "Norwegian", value: "no" },
                          { label: "Pashto", value: "ps" },
                          { label: "Polish", value: "pl" },
                          { label: "Portuguese (Brazil)", value: "pt" },
                          { label: "Portuguese (Portugal)", value: "pt-PT" },
                          { label: "Punjabi", value: "pa" },
                          { label: "Romanian", value: "ro" },
                          { label: "Russian", value: "ru" },
                          { label: "Serbian", value: "sr" },
                          { label: "Sinhala", value: "si" },
                          { label: "Slovak", value: "sk" },
                          { label: "Slovenian", value: "sl" },
                          { label: "Somali", value: "so" },
                          { label: "Spanish", value: "es" },
                          { label: "Spanish (Mexico)", value: "es-MX" },
                          { label: "Swahili", value: "sw" },
                          { label: "Swedish", value: "sv" },
                          { label: "Tamil", value: "ta" },
                          { label: "Telugu", value: "te" },
                          { label: "Thai", value: "th" },
                          { label: "Turkish", value: "tr" },
                          { label: "Ukrainian", value: "uk" },
                          { label: "Urdu", value: "ur" },
                          { label: "Uzbek", value: "uz" },
                          { label: "Vietnamese", value: "vi" },
                          { label: "Welsh", value: "cy" }
                      ]}
                      selectedAriaLabel="Selected"
              />
          </FormField>
          <FormField
              description="Please choose a Language that you would like to localize this content to."
              label="Language to Translate To">
              <Select
                      selectedOption={selectedDestinationLanguage}
                      onChange={({ detail }) =>
                      setSelectedDestinationLanguage(JSON.parse(JSON.stringify(detail.selectedOption)))
                      }
                      options={[
                          { label: "Afrikaans", value: "af" },
                          { label: "Albanian", value: "sq" },
                          { label: "Amharic", value: "am" },
                          { label: "Arabic", value: "ar" },
                          { label: "Armenian", value: "hy" },
                          { label: "Azerbaijani", value: "az" },
                          { label: "Bengali", value: "bn" },
                          { label: "Bosnian", value: "bs" },
                          { label: "Bulgarian", value: "bg" },
                          { label: "Catalan", value: "ca" },
                          { label: "Chinese (Simplified)", value: "zh" },
                          { label: "Chinese (Traditional)", value: "zh-TW" },
                          { label: "Croatian", value: "hr" },
                          { label: "Czech", value: "cs" },
                          { label: "Danish", value: "da" },
                          { label: "Dari", value: "fa-AF" },
                          { label: "Dutch", value: "nl" },
                          { label: "English", value: "en" },
                          { label: "Estonian", value: "et" },
                          { label: "Farsi (Persian)", value: "fa" },
                          { label: "Filipino, Tagalog", value: "tl" },
                          { label: "Finnish", value: "fi" },
                          { label: "French", value: "fr" },
                          { label: "French (Canada)", value: "fr-CA" },
                          { label: "Georgian", value: "ka" },
                          { label: "German", value: "de" },
                          { label: "Greek", value: "el" },
                          { label: "Gujarati", value: "gu" },
                          { label: "Haitian Creole", value: "ht" },
                          { label: "Hausa", value: "ha" },
                          { label: "Hebrew", value: "he" },
                          { label: "Hindi", value: "hi" },
                          { label: "Hungarian", value: "hu" },
                          { label: "Icelandic", value: "is" },
                          { label: "Indonesian", value: "id" },
                          { label: "Irish", value: "ga" },
                          { label: "Italian", value: "it" },
                          { label: "Japanese", value: "ja" },
                          { label: "Kannada", value: "kn" },
                          { label: "Kazakh", value: "kk" },
                          { label: "Korean", value: "ko" },
                          { label: "Latvian", value: "lv" },
                          { label: "Lithuanian", value: "lt" },
                          { label: "Macedonian", value: "mk" },
                          { label: "Malay", value: "ms" },
                          { label: "Malayalam", value: "ml" },
                          { label: "Maltese", value: "mt" },
                          { label: "Marathi", value: "mr" },
                          { label: "Mongolian", value: "mn" },
                          { label: "Norwegian", value: "no" },
                          { label: "Pashto", value: "ps" },
                          { label: "Polish", value: "pl" },
                          { label: "Portuguese (Brazil)", value: "pt" },
                          { label: "Portuguese (Portugal)", value: "pt-PT" },
                          { label: "Punjabi", value: "pa" },
                          { label: "Romanian", value: "ro" },
                          { label: "Russian", value: "ru" },
                          { label: "Serbian", value: "sr" },
                          { label: "Sinhala", value: "si" },
                          { label: "Slovak", value: "sk" },
                          { label: "Slovenian", value: "sl" },
                          { label: "Somali", value: "so" },
                          { label: "Spanish", value: "es" },
                          { label: "Spanish (Mexico)", value: "es-MX" },
                          { label: "Swahili", value: "sw" },
                          { label: "Swedish", value: "sv" },
                          { label: "Tamil", value: "ta" },
                          { label: "Telugu", value: "te" },
                          { label: "Thai", value: "th" },
                          { label: "Turkish", value: "tr" },
                          { label: "Ukrainian", value: "uk" },
                          { label: "Urdu", value: "ur" },
                          { label: "Uzbek", value: "uz" },
                          { label: "Vietnamese", value: "vi" },
                          { label: "Welsh", value: "cy" }
                      ]}
                      selectedAriaLabel="Selected"
              />
          </FormField>
          </SpaceBetween>
      </Container>
    </SpaceBetween>
  );
}


async function localLocalTitlesFromS3() {
  
}
  